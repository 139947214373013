export default {
  label: {
    uploading: 'アップロード中。',
    scaning: 'ウイルススキャン中。',
    no_virus: 'ウイルスが検出しない。',
    have_virus: 'ウイルスが検出した。',
    scan_fail: 'スキャン失敗。',
    scan_timeout: 'タイムアウトしました！ウイルススキャンを完了できませんでした。もう一度お試しいただくか、サポートにお問い合わせください。',
  },
};
