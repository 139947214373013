import http from '@/services/http';

const getDefaultState = () => {
  return {
  };
};

const state = getDefaultState();
const getters = {
};

const mutations = {
};

const actions = {
  async import (_, { url, payload }) {
    return await http.post(url, payload);
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};
