import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLangCommon from './en/common';
import enValidate from './en/validate';

import jaLangCommon from './ja/common';
import jaAuth from './ja/auth';
import jaValidate from './ja/validate';
import jaSystem from './ja/system';
import jaClient from './ja/client';
import jaCompany from './ja/company';
import jaStore from './ja/store';
import jaUser from './ja/user';
import jaTransaction from './ja/transaction';
import jaPromotion from './ja/promotion';
import jaReport from './ja/report';
import jaLog from './ja/log';
import jaInvoice from './ja/invoice';
import jaTax from './ja/tax';
import jaMaintain from './ja/maintain';
import jaManualImport from './ja/manual_import';
import jsInformation from './ja/information';
import jsUpload from './ja/upload';

Vue.use(VueI18n);

const langs = {
  en: {
    common: enLangCommon,
    validate: enValidate,
  },
  ja: {
    common: jaLangCommon,
    validate: jaValidate,
    auth: jaAuth,
    system: jaSystem,
    client: jaClient,
    company: jaCompany,
    store: jaStore,
    user: jaUser,
    transaction: jaTransaction,
    promotion: jaPromotion,
    report: jaReport,
    log: jaLog,
    invoice: jaInvoice,
    tax: jaTax,
    maintain: jaMaintain,
    manual_import: jaManualImport,
    information: jsInformation,
    upload: jsUpload,
  },
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'ja',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ja',
  messages: langs,
  silentFallbackWarn: true,
});
