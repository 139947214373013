const constant = {
  // SCAN virus status
  SCAN_STATUS_PROCESSING: 1,
  SCAN_STATUS_COMPLETED: 2,
  SCAN_STATUS_FAILED: 3,
  SCAN_STATUS_TIMEOUT: 4,

  // Result scan
  RESULT_SCAN_NOT_FOUND_VIRUS: 0,
  RESULT_SCAN_FOUND_VIRUS: 1,
  RESULT_SCAN_TIMEOUT: 2,
  // upload status
  UPLOAD_STATUS_DOING: 'UPLOADING',
  UPLOAD_STATUS_DONE: 'UPLOADED',

  TIMEOUT_FIRST_TIME_CHECK_STATUS: 3000, // Millisecond
  SCHEDULE_TIME_CHECK_STATUS: 5000, // Millisecond
  TIMEOUT_CANCEL_CHECK_STATUS: 60000, // Millisecond
};

export default constant;
