const prefixAdminEndpoint = '/admin';
export default {
  login: prefixAdminEndpoint + '/login',
  logout: prefixAdminEndpoint + '/logout',
  systems: prefixAdminEndpoint + '/systems',
  systems_by_information: prefixAdminEndpoint + '/systems/list-by-information',
  delete_system: prefixAdminEndpoint + '/systems/:id',
  create_system: prefixAdminEndpoint + '/systems/store',
  update_system: prefixAdminEndpoint + '/systems/update/:id',
  detail_system: prefixAdminEndpoint + '/systems/:id',
  companies: prefixAdminEndpoint + '/companies',
  companies_by_information: prefixAdminEndpoint + '/companies/list-by-information',
  delete_company: prefixAdminEndpoint + '/companies/:id',
  create_company: prefixAdminEndpoint + '/companies',
  update_company: prefixAdminEndpoint + '/companies/update/:id',
  detail_company: prefixAdminEndpoint + '/companies/:id',
  import_company: prefixAdminEndpoint + '/companies/imports',
  clients: prefixAdminEndpoint + '/clients',
  clients_by_information: prefixAdminEndpoint + '/clients/list-by-information',
  clients_create: prefixAdminEndpoint + '/clients/create',
  delete_client: prefixAdminEndpoint + '/clients/:id',
  import_client: prefixAdminEndpoint + '/clients/imports',
  detail_client: prefixAdminEndpoint + '/clients/:id',
  client_update: prefixAdminEndpoint + '/clients/update/:id',
  statistical_point_added_by_month: prefixAdminEndpoint + '/transactions/statistical-point-added-by-month',
  promotions_calendar: prefixAdminEndpoint + '/promotions/calendar',
  available_maintenances: prefixAdminEndpoint + '/maintenances/available',
  all_systems: prefixAdminEndpoint + '/systems/all',
  all_systems_by_user: prefixAdminEndpoint + '/systems/all-by-user',
  filter_clients_by_system: prefixAdminEndpoint + '/clients/filter-by-system/:system_id',
  filter_clients_by_system_by_user: prefixAdminEndpoint + '/clients/filter-by-system-by-user/:system_id',
  filter_companies_by_client: prefixAdminEndpoint + '/companies/filter-by-client/:client_id',
  filter_companies_by_client_by_user: prefixAdminEndpoint + '/companies/filter-by-client-by-user/:client_id',
  filter_stores_by_company: prefixAdminEndpoint + '/stores/filter-by-company/:company_id',
  stores: prefixAdminEndpoint + '/stores',
  create_store: prefixAdminEndpoint + '/stores',
  update_store: prefixAdminEndpoint + '/stores/:id/update',
  delete_store: prefixAdminEndpoint + '/stores/:id',
  detail_store: prefixAdminEndpoint + '/stores/:id',
  export_store: prefixAdminEndpoint + '/stores/export-csv',
  import_service_point: prefixAdminEndpoint + '/stores/service-point/import',
  import_store: prefixAdminEndpoint + '/stores/import-csv',
  promotions_create: prefixAdminEndpoint + '/promotions',
  promotions_detail: prefixAdminEndpoint + '/promotions/:id',
  promotions_update: prefixAdminEndpoint + '/promotions/:id/update',
  delete_promotion: prefixAdminEndpoint + '/promotions/:id',
  users: prefixAdminEndpoint + '/users',
  user_delete: prefixAdminEndpoint + '/users/:id',
  user_get: prefixAdminEndpoint + '/users/:id',
  user_update: prefixAdminEndpoint + '/users/:id',
  promotions: prefixAdminEndpoint + '/promotions',
  import_user: prefixAdminEndpoint + '/users/imports',
  export_user: prefixAdminEndpoint + '/users/exports',
  create_user: prefixAdminEndpoint + '/users',
  transactions: prefixAdminEndpoint + '/transactions',
  transactions_export_csv: prefixAdminEndpoint + '/transactions/export-csv',
  transactions_export_csv_cs21: prefixAdminEndpoint + '/transactions/export-csv-cs21',
  transaction_detail: prefixAdminEndpoint + '/transactions/:id',
  transaction_update: prefixAdminEndpoint + '/transactions/:id/update',
  daily_report: prefixAdminEndpoint + '/transactions/daily-report',
  daily_report_improve: prefixAdminEndpoint + '/transactions/daily-report-2',
  daily_report_re_calc: prefixAdminEndpoint + '/transactions/daily-report-recalc',
  daily_report_csv: prefixAdminEndpoint + '/transactions/daily-report-csv',
  daily_report_csv_improve: prefixAdminEndpoint + '/transactions/daily-report-csv-2',
  monthly_report: prefixAdminEndpoint + '/transactions/monthly-report',
  monthly_report_re_calc: prefixAdminEndpoint + '/transactions/monthly-report-recalc',
  monthly_report_improve: prefixAdminEndpoint + '/transactions/monthly-report-2',
  monthly_report_csv: prefixAdminEndpoint + '/transactions/monthly-report-csv',
  monthly_report_csv_improve: prefixAdminEndpoint + '/transactions/monthly-report-csv-2',
  transaction_cancel: prefixAdminEndpoint + '/transactions/:id/cancel',
  taxes: prefixAdminEndpoint + '/taxes',
  create_tax: prefixAdminEndpoint + '/taxes/store',
  delete_tax: prefixAdminEndpoint + '/taxes/delete/',
  list_maintenance: prefixAdminEndpoint + '/maintenances/',
  create_maintenance: prefixAdminEndpoint + '/maintenances/',
  update_maintenance: prefixAdminEndpoint + '/maintenances/:id',
  delete_maintenance: prefixAdminEndpoint + '/maintenances/:id',
  detail_maintenance: prefixAdminEndpoint + '/maintenances/:id',
  list_invoice: prefixAdminEndpoint + '/invoices/',
  create_invoice: prefixAdminEndpoint + '/invoices/',
  delete_invoice: prefixAdminEndpoint + '/invoices/:id',
  import_invoices: prefixAdminEndpoint + '/invoices/import-zip',
  import_update_invoice: prefixAdminEndpoint + '/invoices/:id/import-zip',
  download_invoice: prefixAdminEndpoint + '/invoices/:id/download-pdf',
  batch_send_logs: prefixAdminEndpoint + '/logs/send-log',
  download_send_log: prefixAdminEndpoint + '/logs/download-send-log/:id',
  batch_receive_log: prefixAdminEndpoint + '/logs/receive-log',
  download_point_up_receive_log: prefixAdminEndpoint + '/logs/point-up-receive-log/:id',
  download_error_receive_log: prefixAdminEndpoint + '/logs/error-receive-log/:id',
  all_promotions_by_store: prefixAdminEndpoint + '/promotions/all-by-store/:store_id',
  check_store_point_limit: prefixAdminEndpoint + '/stores/check-point-limit/:store_id',
  update_add_point_transaction: prefixAdminEndpoint + '/transactions/add-point/:id/update',
  update_use_point_transaction: prefixAdminEndpoint + '/transactions/use-point/:id/update',
  card_balance: prefixAdminEndpoint + '/cards/{store_id}/{card_number}/balance',
  operation_logs: prefixAdminEndpoint + '/operation-logs',
  list_manual_import: prefixAdminEndpoint + '/manual-imports',
  import_manual_point_csv: prefixAdminEndpoint + '/manual-imports/{client_id}/import-csv',
  cancel_import_manual_point_csv: prefixAdminEndpoint + '/manual-imports/:manual_import/cancel',
  import_manual_file: prefixAdminEndpoint + '/manual-imports/{client_id}/import-file',
  download_manual_file: prefixAdminEndpoint + '/manual-imports/{id}/download-file',
  get_csdelight_clients: prefixAdminEndpoint + '/clients/filter-by-system-csd',
  multipart: {
    start: prefixAdminEndpoint + '/multipart-upload/start',
    completed: prefixAdminEndpoint + '/multipart-upload/completed',
  },
  list_information: prefixAdminEndpoint + '/informations/',
  create_information: prefixAdminEndpoint + '/informations/',
  update_information: prefixAdminEndpoint + '/informations/:id',
  delete_information: prefixAdminEndpoint + '/informations/:id',
  detail_information: prefixAdminEndpoint + '/informations/:id',
  upload_information_attachment: prefixAdminEndpoint + '/informations/upload-attachment',
  newest_information: prefixAdminEndpoint + '/informations/newest',
  handle_information_request: prefixAdminEndpoint + '/informations/:id/handle-request',
  count_not_yet_approve: prefixAdminEndpoint + '/informations/count-not-yet-approve',
  scan_virus: prefixAdminEndpoint + '/scan_virus/status/:job_id',
  uploadAndScan: prefixAdminEndpoint + '/upload-file-and-scan-virus',
  scanVirusWithS3File: prefixAdminEndpoint + '/scan-virus-with-s3-file',
};
